import * as API from '@/services/API';

export default {
  get(id, options) {
    const query = API.apiHelper.getQueryStringFromObject(options);
    return API.apiClient.get(`/subgroups/${id}${query}`);
  },
  list(options) {
    const query = API.apiHelper.getQueryStringFromObject(options);
    return API.apiClient.get(`/subgroups/${query}`);
  },
  save(id, data) {
    if (id == 0) {
      return API.apiClient.post(`/subgroups`, data);
    } else {
      return API.apiClient.put(`/subgroups/${id}`, data);
    }
  },
  async peopleDuplicates(subgroup_id, person_id, first_name, surname) {
    return API.apiClient.get(
      `subgroups/${subgroup_id}/people-duplicates/?first_name=${first_name}&surname=${surname}&people_id=${person_id}`
    );
  },
  async hostDuplicates(subgroup_id, organisation_id, title) {
    return API.apiClient.get(
      `subgroups/${subgroup_id}/host-duplicates/?title=${title}&organisation_id=${organisation_id}`
    );
  },
  async hostAttach(subgroup_id, host_id) {
    return API.apiClient.post(`/subgroups/${subgroup_id}/hosts/${host_id}`);
  },
  async hostDetach(subgroup_id, host_id) {
    return API.apiClient.delete(`/subgroups/${subgroup_id}/hosts/${host_id}`);
  },
  async peopleAttach(subgroup_id, person_id) {
    return API.apiClient.post(`/subgroups/${subgroup_id}/people/${person_id}`);
  },
  async peopleDetach(subgroup_id, person_id) {
    return API.apiClient.delete(`/subgroups/${subgroup_id}/people/${person_id}`);
  },
  delete(id) {
    return API.apiClient.delete(`/subgroups/${id}`);
  },
  paginate(link) {
    return API.apiClient.get(link);
  }
};

export const namespaced = true;

export const mutations = {};

export const actions = {};

export const state = {
  reports: [
    {
      id: 1,
      name: 'Basic - By Date',
      properties: {
        reportFilenamePrefix: 'Basic-by-date',
        reportName: 'basic-by-date',
        reportClient: true
      },
      component: () =>
        import(/* webpackChunkName: "by-date-export-dialog" */ '../../views/dialogs/reports/ByDateExportDialog')
    },
    {
      id: 2,
      name: 'Basic - Cancellation',
      properties: {
        reportFilenamePrefix: 'Basic-Cancellation',
        reportName: 'basic-cancellation',
        reportClient: true
      },
      component: () =>
        import(/* webpackChunkName: "by-date-export-dialog" */ '../../views/dialogs/reports/ByDateExportDialog')
    },
    {
      id: 4,
      name: 'Certificate of Attendance',
      properties: {
        reportFilenamePrefix: 'Certificate of Attendance',
        reportName: 'certificate-of-attendence'
      },
      component: () =>
        import(/* webpackChunkName: "group-export-dialog" */ '../../views/dialogs/reports/GroupExportDialog')
    },
    {
      id: 5,
      name: 'Deadlines',
      properties: {
        reportFilenamePrefix: 'Deadlines',
        reportName: 'deadlines',
        reportClient: true
      },
      component: () =>
        import(/* webpackChunkName: "group-export-dialog" */ '../../views/dialogs/reports/ByDateExportDialog')
    },
    {
      id: 6,
      name: 'Host Families - By Postcode',
      properties: {
        reportFilenamePrefix: 'Host-Family-By-Postcode',
        reportName: 'host-family-by-post-code'
      },
      component: () =>
        import(/* webpackChunkName: "postcode-export-dialog" */ '../../views/dialogs/reports/PostCodeExportDialog')
    },
    {
      id: 7,
      name: 'Host Family without working with children card details',
      properties: {
        reportFilenamePrefix: 'Host Family without working with children card details',
        reportName: 'host-family-incomplete-blue-card',
        reportClient: false
      },
      component: () =>
        import(/* webpackChunkName: "by-date-export-dialog" */ '../../views/dialogs/reports/ByDateExportDialog')
    },
    {
      id: 8,
      name: 'Itinerary',
      properties: {
        reportFilenamePrefix: 'Itinerary',
        reportName: 'itinerary'
      },
      component: () =>
        import(/* webpackChunkName: "group-export-dialog" */ '../../views/dialogs/reports/GroupExportDialog')
    },
    {
      id: 9,
      name: 'Name List',
      properties: {
        reportFilenamePrefix: 'Name-List',
        reportName: 'namelist'
      },
      component: () =>
        import(/* webpackChunkName: "group-export-dialog" */ '../../views/dialogs/reports/GroupExportDialog')
    },
    {
      id: 10,
      name: 'Name List - Accounts',
      properties: {
        reportFilenamePrefix: 'Name-List-Accounts',
        reportName: 'namelist-accounts'
      },
      component: () =>
        import(/* webpackChunkName: "group-export-dialog" */ '../../views/dialogs/reports/GroupExportDialog')
    },
    {
      id: 11,
      name: 'Name List - Japan',
      properties: {
        reportFilenamePrefix: 'Name-List-Japan',
        reportName: 'namelist-japan'
      },
      component: () =>
        import(/* webpackChunkName: "group-export-dialog" */ '../../views/dialogs/reports/GroupExportDialog')
    },
    {
      id: 12,
      name: 'Name List - Summary',
      properties: {
        reportFilenamePrefix: 'Name-List-Summary',
        reportName: 'namelist-summary'
      },
      component: () =>
        import(/* webpackChunkName: "group-export-dialog" */ '../../views/dialogs/reports/GroupExportDialog')
    },
    {
      id: 13,
      name: 'Standard 1 - By Date',
      properties: {
        reportFilenamePrefix: 'Standard-1',
        reportName: 'standard',
        reportClient: false
      },
      component: () =>
        import(/* webpackChunkName: "by-date-export-dialog" */ '../../views/dialogs/reports/ByDateExportDialog')
    },
    {
      id: 14,
      name: 'Standard 2 - By Date',
      properties: {
        reportFilenamePrefix: 'Standard-2',
        reportName: 'standard-alternative',
        reportClient: false
      },
      component: () =>
        import(/* webpackChunkName: "by-date-export-dialog" */ '../../views/dialogs/reports/ByDateExportDialog')
    },
    {
      id: 3,
      name: 'Working with children report',
      properties: {
        reportFilenamePrefix: 'Blue-Card-Detail-Report',
        reportName: 'bluecard',
        allowedFileTypes: ['xlsx']
      },
      component: () =>
        import(/* webpackChunkName: "group-export-dialog" */ '../../views/dialogs/reports/GroupExportDialog')
    }
  ]
};

export const getters = {
  availableReports: state => {
    return state.reports;
  }
};

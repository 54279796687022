import axios from 'axios';
import store from '@/store';

export const authClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true // required to handle the CSRF token
});

/*
 * Add a response interceptor
 */
authClient.interceptors.response.use(
  response => {
    return response;
  },
  function (error) {
    if (
      error.response &&
      [401, 419].includes(error.response.status) &&
      store.getters['auth/authUser'] &&
      !store.getters['auth/guest']
    ) {
      store.dispatch('auth/logout');
    }
    return Promise.reject(error);
  }
);

export default {
  async login(payload) {
    await authClient.get('/sanctum/csrf-cookie');
    return authClient.post('/login', payload);
  },
  logout() {
    return authClient.post('/logout');
  },
  async forgotPassword(payload) {
    await authClient.get('/sanctum/csrf-cookie');
    return await authClient.post('/forgot-password', payload);
  },
  getAuthUser() {
    return authClient.get('/api/users/auth');
  },
  async resetPassword(payload) {
    await authClient.get('/sanctum/csrf-cookie');
    return await authClient.post('/reset-password', payload);
  },
  async changePassword(payload) {
    await authClient.get('/sanctum/csrf-cookie');
    return await authClient.post('/api/user/change-password', payload);
  },
  // updatePassword(payload) {
  //   return authClient.put('/user/password', payload);
  // },
  async registerUser(payload) {
    await authClient.get('/sanctum/csrf-cookie');
    return authClient.post('/register', payload);
  },
  sendVerification(payload) {
    return authClient.post('/email/verification-notification', payload);
  },
  updateUser(payload) {
    return authClient.put('/user/profile-information', payload);
  },
  async confirmPasswordStatus() {
    return authClient.get('/user/confirmed-password-status');
  },
  confirmPassword(payload) {
    return authClient.post('/user/confirm-password', payload);
  },
  enableTwoFactorAuthentication() {
    return authClient.post('/user/two-factor-authentication');
  },
  showQrCode() {
    return authClient.get('/user/two-factor-qr-code');
  },
  async confirmChallenge(payload) {
    return authClient.post('/two-factor-challenge', payload);
  },
  twoFactorRecoveryCodes() {
    return authClient.get('/user/two-factor-recovery-codes');
  },
  disableTwoFactorAuthentication(id) {
    return authClient.delete('/api/users/two-factor-authentication/' + id);
  }
};
